import React, { useEffect, useState, } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyGetAccountQuery } from '../../store/api/account.api'
import { useAppSelector } from '../../hooks/appHook'
import { notification, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

const Homepage = () => {
  const navigate = useNavigate()
  const [getAccount] = useLazyGetAccountQuery()
  const { account } = useAppSelector((state) => state.account)
  const [error, setError] = useState<null|string>(null)
  const [attempt, setAttempt] = useState(0)

  const { t } = useTranslation('common')

  useEffect(() => {
    const fetchAccountAndRedirect = async () => {
      if (attempt >= 5) {
        setError(t('error'))
        localStorage.clear()
        window.location.reload()
        return
      }

      try {
        if (!account?.id) {
          const result = await getAccount().unwrap()
          if (result?.account.id) {
            navigate(`/c/${result.account.id}`)
          } else {
            setError(t('error'))
            localStorage.clear()
            window.location.reload()
          }
        } else {
          navigate(`/c/${account.id}`)
        }
      } catch (error) {
        console.error('Error fetching account:', error)
        setAttempt((prev) => prev + 1)
        if (attempt < 4) {
          setTimeout(fetchAccountAndRedirect, 1000)
        } else {
          localStorage.clear()
          window.location.reload()
        }
      }
    }

    fetchAccountAndRedirect()
  }, [account, navigate, attempt])


  useEffect(() => {
    if(error){
      notification.error({message:error})
    }
  }, [error])

  return <Spin size="large" spinning={true} />
}

export default Homepage
