import React, { useEffect } from 'react'
import TestChatButton from '../../../../../components/TestChatButton/TestChatButton'
import { useAppSelector } from '../../../../../hooks/appHook'
import {
  useDeleteStorageKnowledgeRecordByIdMutation,
  useLazyGetStorageKnowledgeRecordsByIdQuery,
  useUpdateStorageKnowledgeRecordByIdMutation,
} from '../../../../../store/api/storage.api'
import { EditStorageKnowledgeRecord, StorageKnowledgeRecord } from '../../../../../types/storage.type'
import KnowledgeRecordsList from '../../KnowledgeRecordsList/KnowledgeRecordsList'
import { Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useGetDocumentQuery } from '../../../../../store/api/document.api'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { KnowledgeBaseRoutes } from '../../../../../types/enums/knowledge-base-routes.enum'
import { truncateText } from '../../../../../helpers/strings'

const ReviewAllDataByDocumentTab = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const documentId = queryParams.get('documentId')

  const { t } = useTranslation('knowledgeBase')


  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)

  /** Api */
  const { data: document, isLoading: isGetDocumentLoading } = useGetDocumentQuery(documentId)

  /** Storage Actions */
  const [getStorageKnowledgeRecords, getStorageKnowledgeRecordsQueryResult] =
    useLazyGetStorageKnowledgeRecordsByIdQuery()

  const [updateStorageKnowledgeRecord, updateStorageKnowledgeRecordMutationResult] =
    useUpdateStorageKnowledgeRecordByIdMutation()

  const [deleteStorageKnowledgeRecord, deleteStorageKnowledgeRecordMutationResult] =
    useDeleteStorageKnowledgeRecordByIdMutation()

  useEffect(() => {
    getStorageKnowledgeRecords({ id: currentStorageId, documentId: documentId || undefined })
  }, [currentStorageId, documentId])

  /** Handlers */

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({ id: currentStorageId, vectorId: item.id })
    await getStorageKnowledgeRecords({ id: currentStorageId })
  }

  const handleEditData = async ({ value, documentId, chunkIndex }: EditStorageKnowledgeRecord) => {
    await updateStorageKnowledgeRecord({
      storageId: currentStorageId,
      data: value,
      documentId,
      chunkIndex,
    })

    await getStorageKnowledgeRecords({ id: currentStorageId })
  }

  const onBack = () => {
    queryParams.delete('documentId')
    navigate({
      pathname: '/storage/' + KnowledgeBaseRoutes.documents,
      search: queryParams.toString(),
    })
  }

  const isLoading =
    getStorageKnowledgeRecordsQueryResult.isFetching ||
    deleteStorageKnowledgeRecordMutationResult.isLoading ||
    updateStorageKnowledgeRecordMutationResult.isLoading ||
    isGetDocumentLoading

  return (
    <div className="custom-separator-list-container">
      <div className="tab-header">
        <div className="header-description">
          <div className="header-title-wrapper">
            <Button onClick={onBack}>
              <ArrowLeftOutlined />
              {t('reviewAllDataTab.reviewAllDataByDocumentTab.back')}
            </Button>
            <h2>{truncateText(document?.name || document?.url || 'No data', 40)}</h2>
          </div>
          <p> {t('reviewAllDataTab.reviewAllDataByDocumentTab.reviewAndEdit')}</p>
        </div>
        <TestChatButton />
      </div>
      <KnowledgeRecordsList
        records={getStorageKnowledgeRecordsQueryResult.data?.documents || []}
        loading={isLoading}
        onPressDelete={handleDeleteItem}
        onPressEdit={handleEditData}
      />
    </div>
  )
}

export default ReviewAllDataByDocumentTab
