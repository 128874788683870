import React from 'react'
import { Button } from 'antd'
import './googleButton.less'
import { useTranslation } from 'react-i18next'

interface IProps {
  handleGoogleOAuth: () => void
}

const GoogleButton = ({ handleGoogleOAuth }: IProps) => {
  const { t } = useTranslation('components')

  return (
    <Button
      type="link"
      icon={<img src="/images/logo/google_logo.png" alt="Google" width={36} height={36} className="integration-icon" />}
      className="integration-btn"
      size="large"
      onClick={handleGoogleOAuth}
    >
      {t('ui.googleButton.connectGoogleAccount')}
    </Button>
  )
}

export default GoogleButton
